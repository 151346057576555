import Vue from "vue";
import App from "@/App.vue";
import store from "@/store";
import {initMiddleware, router} from "@/router";
import vuetify from "@/plugins/vuetify";
import * as fb from "@/plugins/firebase";
import initGlobalFilters from "@/plugins/filters";
import { i18n } from "@/plugins/i18n";
import { sync } from "vuex-router-sync";
import "@/plugins/vee-validate";

sync(store, router);
store.dispatch("auth/autoFetchProfiles").finally(() => {
  initMiddleware(); // can only have route-protective middleware after authentication completes.
});

initGlobalFilters();
/*
import VeeValidate from "vee-validate";
import enValidationMessages from "vee-validate/dist/locale/en";
import ltValidationMessages from "vee-validate/dist/locale/lt";

Vue.use(VeeValidate, {
  i18nRootKey: "validations", // customize the root path for validation messages.
  i18n,
  dictionary: {
    en: enValidationMessages,
    lt: ltValidationMessages
  }
});
*/

Vue.config.productionTip = false;
//import wb from "./registerServiceWorker";
//Vue.prototype.$workbox = wb;
/*
//and then use it in main.js
Vue.use(VueAnalytics, {
  id: "UA-12345678-1",
  router,
});*/

document.addEventListener("DOMContentLoaded", () => {
  window.app = new Vue({
    store,
    router,
    vuetify,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
});
/*let app;
fb.auth.onAuthStateChanged(async () => {
  if (!app) {
    app = new Vue({
      store,
      router,
      vuetify,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");

    await store.dispatch("auth/fetchUserProfile")
  } else {
    await store.dispatch("auth/fetchUserProfile")
  }
});*/

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/messaging";
import "firebase/remote-config";

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyBp-75BLJeUjMGGzh-JCQIIBX3zMr_7Yog",
  authDomain: "shampoo-app.firebaseapp.com",
  databaseURL: "https://shampoo-app.firebaseio.com",
  projectId: "shampoo-app",
  storageBucket: "shampoo-app.appspot.com",
  messagingSenderId: "614693493888",
  appId: "1:614693493888:web:6492634d780c7798569a65",
};
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const firestore = firebase.firestore;
const authProvider = {
  EmailAuthProvider: firebase.auth.EmailAuthProvider,
};

const remoteConfig = firebase.remoteConfig();
remoteConfig.settings = {
  fetchTimeMillis: 1000, // One min before timing out while fetching
  minimumFetchIntervalMillis: 1, // very short interval for fields expiration.
};

let messaging = null;

try {
  messaging = firebase.messaging();
  console.log("FCM is loaded");

  messaging.onMessage((payload) => {
    console.log("Message received. ", payload);
    // ...
  });
} catch (error) {
  console.log("FCM is not supported for this browser");
}
/*

let urlSW = `${process.env.BASE_URL}service-worker.js`
navigator.serviceWorker
    .register(urlSW)
    .then( (registration) => {
      console.log('registration', urlSW, JSON.stringify(registration))
      messaging.useServiceWorker(registration);
    });
*/

// Disable deprecated features
db.settings({
  timestampsInSnapshots: true,
});

auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)

//db.enablePersistence({experimentalTabSynchronization: true})

// collection names refs
const COLLECTION_PATH = Object.freeze({
  USERS: "users",
  CAMPAIGNS: "campaigns",
  QUESTIONS: "questions",
  ANSWERS: "answers",
  CONFIGURATIONS: "configurations",
  QUIZ: "quiz",
  ANSWERED_QUESTIONS: "answeredQuestions",
});

const util = {
  downloadImageUrl: async (bucketName, path, id, imgFormat) => {
    let completePath = `${COLLECTION_PATH.CAMPAIGNS}/${bucketName}/${path}/${id}.${imgFormat}`;
    return await storage
      .ref(completePath)
      .getDownloadURL()
      .catch(function (error) {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;
          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
        }
        throw error.code;
      });
  },
};

// export utils/refs
export {
  db,
  auth,
  authProvider,
  storage,
  util,
  firestore,
  messaging,
  COLLECTION_PATH,
  remoteConfig,
};

<template lang="pug">
  v-app
    AppNavBar(v-show="user && user.id")
    v-main.pt-12
      router-view
      notification
</template>

<script>
import Notification from "@/components/notification/Notification";
import AppNavBar from "@/components/common/AppNavBar";

import { mapGetters } from "vuex";
import AppTopBar from "@/components/common/AppTopBar";

export default {
  name: "App",

  components: { AppTopBar, Notification, AppNavBar },
  mounted() {},
  methods: {},
  computed: {
    ...mapGetters({
      user: "auth/userProfile",
    }),
  },
  data() {
    return {
      prompt: false,
    };
  },

  created() {},
};
</script>

<style lang="scss"></style>

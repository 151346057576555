import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/auth.store";
import notification from "@/store/notification.store";
import user from "@/store/user.store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { auth, notification, user },
});

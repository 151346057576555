<template lang="pug">
  div(v-if="hasProfile")
    v-app-bar(fixed dense)
      v-app-bar-nav-icon(right @click='drawer = true')
    v-navigation-drawer.primary(dark v-model='drawer' :mini-variant.sync='mini' app )
      v-list-item.px-2(@click.stop='mini = !mini')
        v-list-item-avatar
          v-img(contain :src="require('@/assets/logo310x310.png')" transition='scale-transition' width='100' )
        v-list-item-title {{user.name}}
      v-divider
      v-list(flat)
        v-list-item(v-for='(item, i) in items' :key='i' link @click.stop="routeTo(i, item)")
          v-list-item-icon.d-flex.justify-center
            v-icon {{ item.icon }}
          v-list-item-content
            v-list-item-title {{ item.title }}
        div(class="navbar__toggle-icon")
          v-btn(icon='' @click.stop='mini = !mini')
            v-icon(v-show="!mini" small color="primary" ) faL fa-chevron-left
            v-icon(v-show="mini" small color="primary" ) faL fa-chevron-right

</template>

<script>
import Avatar from "@/components/common/Avatar";
import { mapGetters, mapActions } from "vuex";
import { ROUTE_PATHS } from "@/router";
export default {
  name: "AppNavBar",
  components: { Avatar },
  data: () => ({
    drawer: true,
    mini: true,
    items: [
      {
        icon: "fas fa-user",
        title: "Users",
        route: ROUTE_PATHS.USERS
      },
      {
        icon: "fas fa-air-freshener",
        title: "Product",
        route: ROUTE_PATHS.PRODUCTS,
      },
      {
        icon: "fas fa-boxes",
        title: "Inventory",
        route: ROUTE_PATHS.INVENTORY,
      },
      {
        icon: "fas fa-sign-out-alt",
        title: "Sign out",
        logout: true,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      user: "auth/userProfile",
    }),
    hasProfile() {
      return this.user && this.user.id;
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    routeTo(i, item) {
      if (item.logout) {
        this.logout()
        return
      }
      this.$router.push(item.route);
    },

  },
};
</script>

<style lang="scss">
.navbar__toggle-icon {
  background-color: $accent-lighter;
  position: absolute;
  bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
</style>

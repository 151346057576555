export default {
  set: (key, value) => {
    if (!key) {
      return;
    }

    if (!value) {
      localStorage.removeItem(key);
    }

    if (typeof value === "object") {
      value = JSON.stringify(value);
    }

    localStorage.setItem(key, value);
  },
  get: (key) => {
    let value = localStorage.getItem(key);

    if (!value) {
      return;
    }

    // assume it is an object that has been stringified
    if (value[0] === "{") {
      value = JSON.parse(value);
    }

    return value;
  },
};

<template lang="pug">
  div
    v-app-bar(fixed dense)
      v-btn(icon='' @click="fireAction")
        v-icon(small='' color="primary") {{iconName}}
      v-toolbar-title {{title}}
    v-spacer.py-5
</template>
<script>
export default {
  name: "AppTopBar",
  props: {
    iconName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    fireAction() {
      this.$emit("action");
    },
  },
};
</script>
<style lang="scss" scoped></style>

import axios from "axios";
import * as fb from "@/plugins/firebase";

const API_URL = process.env.VUE_APP_QUIZ_API_URL;
const API_KEY = process.env.VUE_APP_QUIZ_API_KEY;

const publicInstance = axios.create({ baseURL: API_URL });
const privateInstance = axios.create({ baseURL: API_URL });

const OnResponse = () => {
  return (response) => {
    if (response.data && response.data.success && response.data.body) {
      return response.data.body;
    } else {
      return OnError(response.error);
    }
  };
};

const OnError = (error) => {
  return Promise.reject(error);
};

// Response interceptor for API calls
publicInstance.interceptors.response.use(OnResponse(), OnError());

// Request interceptor for API calls
privateInstance.interceptors.request.use(
  async function (config) {
    const token = await fb.auth.currentUser.getIdToken(true);

    config.headers["X-Api-Authentication"] = `api-key ${API_KEY}`;
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["Accept"] = "application/json";
    config.headers["Content-Type"] = "application/json";
    config.headers["Access-Control-Allow-Origin"] = "*";

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
privateInstance.interceptors.response.use(OnResponse(), OnError());

const myAxios = {
  publicInstance: publicInstance,
  privateInstance: privateInstance,
};

export default myAxios;

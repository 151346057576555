import myAxios from "@/plugins/axios";
import { PERMISSION } from "@/constant/app";

export default {
  async searchUsers(filter) {
    return myAxios.privateInstance
      .post(`/user/search`, filter)
      .catch((error) => {
        throw new Error(error);
      });
  },
  async loadUser(id) {
    return myAxios.privateInstance.get(`/v2/user/${id}`).catch((error) => {
      throw new Error(error);
    });
  },
  async update(id, user) {
    return myAxios.privateInstance
      .post(`/v2/user/${id}`, user)
      .catch((error) => {
        throw new Error(error);
      });
  },
  hasAccess: (arr1) =>
    arr1.some(
      (el) =>
        el in
        Object.fromEntries(
          [
            PERMISSION.ADMIN,
            PERMISSION.PACKER,
            PERMISSION.CUSTOMER_SUPPORT,
          ].map((key) => [key, true])
        )
    ),
};

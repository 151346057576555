import { NOTIFICATION_TYPE } from "@/constant/app";

const notificationStore = {
  namespaced: true,

  state: {
    notification: null,
  },

  mutations: {
    setNotification(state, val) {
      state.notification = val;
    },
  },

  actions: {
    showSuccess({ commit }, message) {
      commit("setNotification", {
        type: NOTIFICATION_TYPE.SUCCESS,
        multiLine: false,
        text: message,
      });
    },
    showInfo({ commit }, message) {
      commit("setNotification", {
        type: NOTIFICATION_TYPE.INFO,
        multiLine: false,
        text: message,
      });
    },
    showError({ commit }, message) {
      commit("setNotification", {
        type: NOTIFICATION_TYPE.ERROR,
        multiLine: false,
        text: message,
      });
    },
    showWarn({ commit }, message) {
      commit("setNotification", {
        type: NOTIFICATION_TYPE.WARN,
        multiLine: false,
        text: message,
      });
    },
  },
};
export default notificationStore;

import myAxios from "@/plugins/axios";

export default {
  async fetchProfile() {
    return myAxios.privateInstance
      .get(`/internal-user/profile`)
      .catch((error) => {
        throw new Error(error);
      });
  },
};

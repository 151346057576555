import parse from "date-fns/parse";
import format from "date-fns/format";

const ServicesUtil = {
  getResponse: (data, errorMessage) => {
    return {
      success: true,
      message: errorMessage,
      data: data,
    };
  },
  formatFromISO: (dateToFormat, DATE_FMT) => {
    return format(parse(dateToFormat), DATE_FMT || "DD-MM-YYYY HH:mm");
  },
};

export { ServicesUtil };

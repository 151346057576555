import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

let langEN = require("@/lang/en.json");
let langLT = require("@/lang/lt.json");
let validationLT = require("@/lang/validation-lt.json");
let validationEN = require("@/lang/validation-en.json");

langEN.validation = validationEN.messages;
langLT.validation = validationLT.messages;

let locale = {
  locale: "lt",
  messages: {
    lt: langLT,
    en: langEN,
  },
};

const i18n = new VueI18n(locale);

export { i18n };

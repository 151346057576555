import * as fb from "@/plugins/firebase";
import { i18n } from "@/plugins/i18n";
import { ROUTE_PATHS, router } from "@/router";
import { ServicesUtil } from "@/service/services.util";
import InternalUserService from "@/service/internal-user.service";
import UserService from "@/service/user.service";
import StorageService from "@/service/storage.service";

const ERROR_CODES = Object.freeze({
  WRONG_PASSWORD: "auth/wrong-password",
  TOO_MANY_REQUESTS: "auth/too-many-requests",
  REQUIRES_RECENT_LOGIN: "auth/requires-recent-login",
  EMAIL_ALREADY_IN_USE: "auth/email-already-in-use",
});

const authStore = {
  namespaced: true,

  state: {
    user: {},
    userProfile: {},
  },

  getters: {
    userProfile(state) {
      //StorageService.get("userProfile");
      return state.userProfile
    },
  },

  mutations: {
    setUser(state, val) {
      state.user = val;
    },
    setUserProfile(state, val) {
      StorageService.set("userProfile", val);
      state.userProfile = val;
    },
  },

  actions: {
    async autoFetchProfiles({ dispatch }, user) {
      return dispatch("fetchUserProfile", user);
    },
    async login({ dispatch }, form) {
      // sign user in
      await fb.auth
        .signInWithEmailAndPassword(form.email, form.password)
        .then((user) => {
          // fetch user profile and set in state
          dispatch("fetchUserProfile", user);
        })
        .catch((reason) => {
          if (reason) {
            if (reason.code === ERROR_CODES.WRONG_PASSWORD) {
              dispatch(
                "notification/showError",
                i18n.t("login.auth.message.invalid-login"),
                {
                  root: true,
                }
              );
            } else if (reason.code === ERROR_CODES.TOO_MANY_REQUESTS) {
              dispatch(
                "notification/showError",
                i18n.t("login.auth.message.too-many-attempts"),
                { root: true }
              );
            } else {
              dispatch(
                "notification/showError",
                i18n.t("login.auth.message.login-error"),
                { root: true }
              );
            }
          }
        });
    },
    async fetchUserProfile({ commit, dispatch }, user) {
      let fbUser = user || fb.auth.currentUser;

      if (!fbUser) {
        await fb.auth.signOut();

        dispatch("cleanState")

        return
      }

      if (fbUser) {
        let userProfile = await InternalUserService.fetchProfile();

        if (
          !UserService.hasAccess(userProfile.permissions) &&
          !userProfile.blocked
        ) {
          await fb.auth.signOut();
          dispatch(
            "notification/showError",
            i18n.t("login.auth.message.invalid-login"),
            { root: true }
          );
          dispatch("cleanState")

          return
        }

        commit("setUserProfile", userProfile);

        // change route to dashboard
        await router.push(ROUTE_PATHS.USERS);

        return
      }
    },
    async logout({ dispatch }) {
      await fb.auth.signOut();

      dispatch("cleanState")
    },
    async cleanState({ commit }) {
      commit("setUser", null);
      commit("setUserProfile", null);

      await router.push(ROUTE_PATHS.LOGIN);
    },
    resetPassword({ commit }, form) {
      // sign user in
      fb.auth
        .sendPasswordResetEmail(form.email)
        .then(() => {
          commit("setUser", null);
          commit("setUserProfile", null);
        })
        .catch((reason) => {
          return ServicesUtil.getResponse(null, reason.message);
        });
    },
    confirmPasswordReset({ dispatch }, form) {
      // sign user in
      fb.auth
        .confirmPasswordReset(form.code, form.newPassword)
        .then(() => {
          dispatch(
            "notification/showSuccess",
            i18n.t("login.password-reset.message.confirmation"),
            { root: true }
          );
          router.push(ROUTE_PATHS.APP_HOME);
        })
        .catch((reason) => {
          return ServicesUtil.getResponse(null, reason.message);
        });
    },
    changePassword({ commit, dispatch }, form) {
      let user = fb.auth.currentUser;

      if (user) {
        user
          .updatePassword(form.newPassword)
          .then(() => {
            user
              .reauthenticateWithCredential(
                fb.authProvider.EmailAuthProvider.credential(
                  user.email,
                  form.newPassword
                )
              )
              .then(async () => {
                let userProfile = await UserService.load({ email: user.email });
                // set user data
                commit("setUser", fb.auth.currentUser);

                commit("setUserProfile", userProfile);

                dispatch(
                  "notification/showSuccess",
                  i18n.t("login.change-password.message.confirmation"),
                  { root: true }
                );
                await router.push(ROUTE_PATHS.ADMIN_HOME);
              });
          })
          .catch(async (reason) => {
            if (reason) {
              if (reason.code === ERROR_CODES.REQUIRES_RECENT_LOGIN) {
                await fb.auth.signOut();
                dispatch(
                  "notification/showError",
                  i18n.t("login.change-password.message.session-expired"),
                  { root: true }
                );
                dispatch("cleanState")
              } else {
                dispatch(
                  "notification/showError",
                  i18n.t(
                    "login.change-password.message.error-changing-password"
                  ),
                  { root: true }
                );
              }
            }
          });
      } else {
        dispatch(
          "notification/showSuccess",
          i18n.t("login.change-password.message.session-expired"),
          { root: true }
        );
        router.push(ROUTE_PATHS.ADMIN_HOME);
      }
    },
  },
};

export default authStore;

import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "@/plugins/firebase";
import store from "@/store";

import UserService from "@/service/user.service";
import StorageService from "@/service/storage.service";
import * as fb from "@/plugins/firebase"; //or use a full path to ./store

Vue.use(VueRouter);

const ROUTE_PATHS = Object.freeze({
  ADMIN_HOME: "/home",
  LOGIN: "/",
  USERS: "/users",
  INVENTORY: "/inventory",
  USER_DETAILS: "/users/:id",
  PRODUCTS: "/products",
});

const routes = [
  {
    path: ROUTE_PATHS.LOGIN,
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "LoginHome" */ "@/views/login/Login.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: ROUTE_PATHS.ADMIN_HOME,
    name: "Home",
    component: () => import(/* webpackChunkName: "Home" */ "@/views/Home.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: ROUTE_PATHS.USERS,
    name: "UsersOverview",
    component: () =>
      import(
        /* webpackChunkName: "UsersOverview" */ "@/views/user/UsersOverview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: ROUTE_PATHS.USER_DETAILS,
    name: "UserDetails",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "UserDetails" */ "@/views/user/UserDetails.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: ROUTE_PATHS.INVENTORY,
    name: "InventoryOverview",
    component: () =>
      import(
        /* webpackChunkName: "InventoryOverview" */ "@/views/inventory/InventoryOverview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: ROUTE_PATHS.PRODUCTS,
    name: "ProductsOverview",
    component: () =>
      import(
        /* webpackChunkName: "ProductsOverview" */ "@/views/product/ProductsOverview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export const initMiddleware = () => {
  router.beforeEach((to, from, next) => {
    const currentPath = window.location.pathname;

    const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

    if (!requiresAuth) {
      next();
      return;
    }

    let userProfile = StorageService.get("userProfile");

    let isAdmin =
      auth.currentUser &&
      UserService.hasAccess(userProfile.permissions) &&
      !store.state.auth.userProfile.blocked;

    if (!isAdmin) {
      next({ name: ROUTE_PATHS.APP_HOME, query: { currentPath } });
    } else {
      next();
    }
  });

  fb.auth.onAuthStateChanged(user => {
    store.dispatch("auth/autoFetchProfiles", user);
  });
};

export { router, ROUTE_PATHS };

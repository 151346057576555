import { configure, extend } from "vee-validate";
import { email, min, required } from "vee-validate/dist/rules";
import { i18n } from "@/plugins/i18n";

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    //values._field_ = i18n.t(`global.field.${field}`);
    values._field_ = field;

    return i18n.t(`validation.${values._rule_}`, values);
  },
});

// Install required rule and message.
extend("required", required);

// Install email rule and message.
extend("email", email);

// Install min rule and message.
extend("min", min);

// Install custom rule validation to compare passwords
extend(
  "comparePasswords",
  (value, [otherValue]) => {
    return value === otherValue;
  },
  {
    hasTarget: true,
  }
);

import UserService from "@/service/user.service";

const userStore = {
  namespaced: true,
  state: {},

  getters: {},

  mutations: {},

  actions: {
    async searchUsers(_, filter) {
      return await UserService.searchUsers(filter);
    },
    async loadUser(_, id) {
      return await UserService.loadUser(id);
    },
  },
};

export default userStore;

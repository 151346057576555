import Vue from "vue";
import Vuetify from "vuetify/lib";
import lt from "vuetify/lib/locale/lt";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas, faL } from "@fortawesome/free-solid-svg-icons";

Vue.component("font-awesome-icon", FontAwesomeIcon); // Register component globally
library.add(fas); // Include needed icon
library.add(faL); // Include needed icon// s

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#ca987f",
        secondary: "#6a6f5b",
        accent: "#f2e4d9",
        error: "#7A4014",
        info: "#3E4E50",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  lang: {
    locales: { lt },
    current: "lt",
  },
  icons: {
    iconfont: "faSvg",
  },
});

<template lang="pug">
  v-avatar(:size="size" color="#F7CE68")
    img(v-if="showImage" :src="src")
    span(v-if="showTitle") {{title.substring(0,1)}}
    v-icon(v-if="showIcon") {{iconName}}
</template>
<script>
export default {
  name: "Avatar",
  props: {
    iconName: {
      type: String,
      required: false,
      default: "fa-user-circle",
    },
    src: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: "40px",
    },
  },
  computed: {
    showImage() {
      return this.src;
    },
    showTitle() {
      return !this.showImage && this.title;
    },
    showIcon() {
      return !this.showImage && !this.showTitle;
    },
  },
};
</script>

<template lang="pug">
  v-snackbar(v-model='notification' :multi-line='notification.multiLine' :color="notificationColor" v-if="notification")
    | {{ notification.text }}
    template(v-slot:action='{ attrs }')
      v-btn(color='primary' text='' v-bind='attrs' @click='closeNotification')
        | Close
</template>

<script>
export default {
  name: "Notification",
  data: () => ({}),
  computed: {
    notification: {
      get() {
        return this.$store.state.notification.notification;
      },
      set(notification) {
        this.$store.state.notification.notification = notification;
      },
    },
    notificationColor() {
      let color = "info";
      if (this.notification) {
        switch (this.notification.type) {
          case "error":
            color = "danger";
            break;
          case "success":
            color = "success";
            break;
          case "warn":
            color = "warning";
            break;
          default:
            color = "info";
            break;
        }
      }

      return color;
    },
  },
  methods: {
    closeNotification() {
      this.notification = null;
    },
  },
};
</script>

<style scoped></style>

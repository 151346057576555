import Vue from "vue";
import parse from "date-fns/parse";
import format from "date-fns/format";

const initGlobalFilters = () => {
  Vue.filter("boolToYesNo", (val) => (val ? "Yes" : "No"));
  Vue.filter("toNumericalDateTimeString", (d) =>
    format(parse(d), "DD-MM-YYYY HH:mm")
  );
  Vue.filter(
    "toHumanizedDateTimeString",
    (d) => `${format(parse(d), "D MMMM YYYY")} at ${format(parse(d), "HH:mm")}`
  );
  Vue.filter("toHumanizedDateString", (d) => format(parse(d), "D MMMM YYYY"));
};

export default initGlobalFilters;
